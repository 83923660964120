document.addEventListener("hs:modal:close", (event: Event) => {
	const modalEl = document.getElementById(
		(event as CustomEvent<{ modalId: string }>).detail.modalId,
	);

	if (modalEl) {
		// HACK: LB - trigger events on the modal to close it
		// We should instead be able to emit an event that programmatically closes
		// the modal, but we need to either:
		//  - use Bootstrap's jQuery API to trigger a close - not happening, or
		//  - rewrite the modal implementation without Bootstrap
		modalEl.dispatchEvent(new FocusEvent("focusout")); // remove focus from the modal for assistive devices
		modalEl.dispatchEvent(new MouseEvent("click"));
	}
});
